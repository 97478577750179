require('./bootstrap');
require("./jquery.lazyload.min");

let phoneCodes = require('./phoneCodes')
window.sydonios = {}
window.sydonios.countriesCodes = phoneCodes.getPhoneCodes()

$(document).ready(function () {
    function checkBasketLength() {
        if ($('.tooltipGoods').children().length == 0) {
            $('.tooltip-basket').css('display', 'none')
        } else {
            $('.tooltip-basket').css('display', '')
        }
    }

    checkBasketLength()
    //----------------------------
    let timeoutTime = 300, optionSelected, parent

    $('span.ic').on('click', function (e) {
        $('span.ic').toggleClass('check-ic')
        $('.order-btn').toggleClass('btn-secondary')
        $('.order-btn').toggleClass('btn-primary')

    })

    $('input[name="phone"], input[name="email"], input[name="client-name"], #street-delivery').blur(function () {
        let phoneRegex = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
            emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if ($(this).val() === '' || $(this).val().trim() === 'г Москва,') return
        if ($(this).attr('name') === 'phone') {
            if (`+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex)) {
                $(this).parent().removeClass('is-invalid')
            } else {
                $(this).parent().addClass('is-invalid')
            }
        } else if ($(this).attr('name') === 'email') {
            if ($('input[name="email"]').val().match(emailRegex)) {
                $(this).parent().removeClass('is-invalid')
            } else {
                $(this).parent().addClass('is-invalid')
            }
        } else if ($(this).attr('name') === 'client-name') {
            if ($('input[name="client-name"]').val() !== '') {
                $(this).parent().removeClass('is-invalid')
            }
        } else if ($(this).attr('id') === 'street-delivery') {
            if ($(this).val().trim() !== 'г Москва,' && $('#delivery-sum').text() !== '- руб') {
                $(this).parent().removeClass('is-invalid')
            }
        }
    })

    $('.order-btn').on('click', function (e) {
        if ($(this).hasClass('btn-secondary')) {
            e.preventDefault()
            return
        }

        if ($(this).prop("tagName") == 'INPUT') {
            e.preventDefault()
        }

        let phoneRegex = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
            emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let data
        let isValid = true
        let segment = $(location).attr('href').split('/')[3]
        if (segment == 'place-your-order') {
            let phoneNumber = `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex) ? `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex)[0] : null,
                email = $('input[name="email"]').val().match(emailRegex) ? $('input[name="email"]').val().match(emailRegex)[0] : null,
                clientName = $('input[name="client-name"]').val().trim(),
                paymentType = $('.selectPayment .item input:checked ~ label').attr('data-type')
            data = { phoneNumber, email, clientName, paymentType }

            if (phoneNumber == null || phoneNumber == '') {
                isValid = false
                $('input[name="phone"]').parent().addClass('is-invalid')
            } else {
                $('input[name="phone"]').parent().removeClass('is-invalid')
            }

            if (clientName == null || clientName == '') {
                isValid = false
                $('input[name="client-name"]').parent().addClass('is-invalid')
            } else {
                $('input[name="client-name"]').parent().removeClass('is-invalid')
            }

            if (email == null || email == '') {
                isValid = false
                $('input[name="email"]').parent().addClass('is-invalid')
            } else {
                $('input[name="email"]').parent().removeClass('is-invalid')
            }

            if ($('.tabOrder a.active').attr('data-type') == 'delivery') {
                if ($('#delivery-city').val() == 'Доставка в г. Москва') {
                    if ($('#street-delivery').val().trim() == 'г Москва,' || $('#delivery-sum').text() == '- руб') {
                        isValid = false
                        $('#street-delivery').parent().addClass('is-invalid')
                    }
                    data.date = $('input.datePicker').val()
                    data.flatOffice = $('input[name="flat-office"]').val()
                    data.commentCourier = $('textarea[name="comment-courier"]').val()
                    data.deliveryCity = 'moscow'
                } else {
                    data.deliveryCity = 'other'
                }
            } else if ($('.tabOrder a.active').attr('data-type') == 'selfTake') {
                if (!Number.isInteger(window.sydonios.selfTakeShopId)) {
                    return
                }
                data.shopId = window.sydonios.selfTakeShopId
            }
        } else if (segment == 'pre-order') {
            let phoneNumber = `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex) ? `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex)[0] : null,
                clientName = $('input[name="client-name"]').val().trim(),
                productQuantity = $('input.product-quantity').val(),
                productId = $('.data-parent').attr('data-newpackid')

            if (clientName == null || clientName == '') {
                isValid = false
                $('input[name="client-name"]').parent().addClass('is-invalid')
            } else {
                $('input[name="client-name"]').parent().removeClass('is-invalid')
            }

            if (phoneNumber == null || phoneNumber == '') {
                isValid = false
                $('input[name="phone"]').parent().addClass('is-invalid')
            } else {
                $('input[name="phone"]').parent().removeClass('is-invalid')
            }
            data = { phoneNumber, clientName, productQuantity, productId }
        } else if (segment == 'order-by-phone') {
            phoneNumber = `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex) ? `+${$('input[name="phone"]').val().replace(/[^0-9]/g, '')}`.match(phoneRegex)[0] : null

            if (phoneNumber == null || phoneNumber == '') {
                isValid = false
                $('input[name="phone"]').parent().addClass('is-invalid')
            } else {
                $('input[name="phone"]').parent().removeClass('is-invalid')
            }

            data = { phoneNumber }
        }
        data.type = segment
        if (!isValid) return
        debounce(
            makeAjaxRequest,
            [
                'onMakeOrder',
                function (data) {
                    $(location).attr('href', data.result)
                },
                data
            ],
            timeoutTime)
    })

    $('select').select2({ theme: "bootstrap4", minimumResultsForSearch: -1 })

    function findParentByClass(htmlElement, classString) {
        while (true) {
            if (!htmlElement.parentElement || htmlElement.classList.contains(classString)) {
                return htmlElement
            }
            htmlElement = htmlElement.parentElement
        }
    }

    function makeAjaxRequest(route, success, data) { $.request(route, { data, success }) }

    function debounce(func, params, timeout) {
        window.sydoniosLastCall = Date.now()
        setTimeout(function () {
            if (Date.now() - window.sydoniosLastCall > timeout) {
                func(...params)
            }
        }, timeout + 20)
    }

    function updateDomBasketDropdown(data) {
        let html = $.parseHTML(data.basketDropdown)
        $('.tooltip-basket').empty()
        $('span.count').text(html.filter(el => el.className == 'tooltipGoods')[0].children.length)
        $('.tooltip-basket').append(...html)
        checkBasketLength()
    }

    let updateBasketData = (parent) => {
        return {
            quantity: parent.dataset.quantity ? parent.dataset.quantity : 1,
            changeQuantity: parent.dataset.changequantity ? parent.dataset.changequantity : 0,
            newPackId: parent.dataset.newpackid ? parent.dataset.newpackid : parent.dataset.packid,
            prevPackId: parent.dataset.prevpackid ? parent.dataset.prevpackid : parent.dataset.newpackid,
            isDeleted: parent.dataset.isdeleted ? parent.dataset.isdeleted : 0
        }
    }

    //--------------------------card
    function changePrice(quantitySelector, packSelector, priceSelector) {
        quantity = $(quantitySelector).val()
        packPrice = parseFloat($(packSelector).val())
        price = (quantity * packPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        $(priceSelector).text(`${price} руб`)
    }

    $('.goodsSection input.form-control').keypress(function (e) {
        if (e.charCode == 13) {
            e.preventDefault()
            parent = findParentByClass(e.target, "goodsSection")
            parent.dataset.quantity = $("input").val()
            parent.dataset.changequantity = $("input").val()
            $('.roboto32').text(($('.goodsSection select').val() * parent.dataset.quantity).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + ' руб')
        }
    })
    setTimeout(() => {
        $('.data-parent .btn-increment, .data-parent .btn-decrement').on('click', function (e) {
            parent = findParentByClass(e.target, "data-parent")
            if (e.target.classList.contains("btn-increment")) {
                parent.dataset.quantity++
                parent.dataset.changequantity++
            } else if (parent.dataset.quantity == 1) {
                return
            } else {
                parent.dataset.quantity--
                parent.dataset.changequantity--
            }
            let price = $('.data-parent select').val()
            if(price == undefined) {
                price = window.firstPrice
            }
            $('.product-price').text((price * parent.dataset.quantity).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + ' руб')
        })
    }, 0)

    $('.data-parent .select2-container').on('select2:select', function (e) {
        optionSelected = $("option:selected", this)
        parent = findParentByClass(e.target, "data-parent")
        parent.dataset.newpackid = optionSelected[0].dataset.packid
        $('.product-price').text((Math.trunc($('.data-parent select').val()) * parent.dataset.quantity).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + ' руб')
        
        ShowAndHideBasketButtons()
    }) 

    ShowAndHideBasketButtons()

    function ShowAndHideBasketButtons() {
        let packInStockSelector = $('.select2-container option:selected').data('packisinstock')
        let packInStock = (packInStockSelector == undefined ? Number(window.firstInStock) : Number(packInStockSelector));
        if (packInStock) {
            $('.pre-order-class').hide();
            $('.is-in-stock-class').show();
        } else {
            $('.pre-order-class').show();
            $('.is-in-stock-class').hide();
        }
    }

    $('#cardAddBasket').on('submit', function (e) {
        e.preventDefault()
        parent = findParentByClass(e.target, "goodsSection")
        debounce(makeAjaxRequest, ['onUpdateBasket', updateDomBasketDropdown, updateBasketData(parent)], timeoutTime)
    });

    //----------------------------basketList
    function updateBasketSuccess(data) {
        let basketDropdown = $.parseHTML(data.basketDropdown)
        $('.tooltip-basket').empty()
        $('.tooltip-basket').append(...basketDropdown)
        $('.count').innerHTML = $('.item').length
        let basketList = $.parseHTML(data.basketList)
        $('div.goodsBox').empty()
        $('div.goodsBox').append(...basketList)
        $('p.h2').text('руб. ' + data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
        $('span.count').text(basketList.filter(el => el.className == 'goods-item').length)
        closeBootstrapDropdowns()
        bindBasketHandlers()
        checkBasketLength()
        if (data.hasOwnProperty('shouldBeRefreshed') && data.shouldBeRefreshed === true) {
            location.reload()
        }
    }

    function closeBootstrapDropdowns() {
        $('select').select2({ theme: "bootstrap4", minimumResultsForSearch: -1 })
        $('select').select2('close')
        $('.select2-dropdown').remove()
    }

    function bindBasketHandlers() {
        $('select').select2({ theme: "bootstrap4", minimumResultsForSearch: -1 })

        $('.goods-item .select2-container').on('select2:select', function (e) {
            optionSelected = $("option:selected", this)
            parent = findParentByClass(e.target, "goods-item")
            parent.dataset.prevpackid = parent.dataset.newpackid
            parent.dataset.newpackid = optionSelected[0].dataset.packid
            debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
        })

        $('.goods-item button.btn-increment, .goods-item button.btn-decrement').on('click', function (e) {
            parent = findParentByClass(e.target, "goods-item")
            if (e.target.classList.contains("btn-increment")) {
                parent.dataset.quantity++
                parent.dataset.changequantity++
            } else if (parent.dataset.quantity == 1) {
                return
            } else {
                parent.dataset.quantity--
                parent.dataset.changequantity--
            }
            $(`input[data-packid="${parent.dataset.newpackid}"]`).val(parent.dataset.quantity)
            debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
        })

        $('.goods-item input.form-control').keypress(function (e) {
            if (e.charCode == 13) {
                e.preventDefault()
                parent = findParentByClass(e.target, "goods-item")
                parent.dataset.changequantity = $('.goods-item input.form-control').val() - parent.dataset.quantity
                parent.dataset.quantity = $('.goods-item input.form-control').val()
                debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
            }
        })

        $('.goods-item input.form-control').on('change', function (e) {
            parent = findParentByClass(e.target, "goods-item")
            parent.dataset.quantity = this.value
            debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
        })

        $('button.close').on('click', function (e) {
            parent = findParentByClass(e.target, "goods-item")
            parent.dataset.isdeleted = 1
            debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
            setTimeout(() => { debounce(makeAjaxRequest, ['onCheckBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime) }, 6000)
        })

        $('.recover-wrap a.link').on('click', function (e) {
            parent = findParentByClass(e.target, "grey")
            parent.dataset.isdeleted = 0
            debounce(makeAjaxRequest, ['onUpdateBasket', updateBasketSuccess, updateBasketData(parent)], timeoutTime)
        })
    }
    bindBasketHandlers()
    //----------------------------
    if ($('#instafeed').length) {
        Instafeed = require('instafeed.js');
    }
    //----------------------------
    $('#add_product').click(function () {
        $(this).text('В корзине')
        $('.addProduct').addClass('active')
    });
    $('.addProduct .close-ic').click(function () {
        $('.addProduct').removeClass('active')
    });

    //----------------------------
    // $('input[name*="phone"]').mask("+7 (999) 999 - 99 - 99");
    //----------------------------
    $('.datePicker').datepicker()
    //----------------------------
    var quantity = $(".quantity input[type='number']");
    quantity.inputSpinner();
    //----------------------------
    $('.hamburger').click(function () {
        $(this).toggleClass('is-active');
        $('header .nav').toggleClass('active');
        $('header').toggleClass('open');
        $('body').toggleClass('hidden');
    });
    //----------------------------
    window.wow.init();

    //----------------------------
    $('[data-fancybox]').fancybox({
        loop: true,
    });
    //-----------------------------------
    window.objectFitImages('img');
    //-----------------------------------

    if ( $('.dropdown').hasClass('dropdown-card')){
        $(".dropdown").select2({
            theme: "bootstrap4 dropdown-card animated fadeInUp",
            minimumResultsForSearch: -1,
            // ajax: {
            //     animation-delay: 2s;
            // }
        });
    }else {
        $(".dropdown").select2({
            theme: "bootstrap4",
            minimumResultsForSearch: -1,
        });
    }
    //-----------------------------------
    var placeholder = $('.form-control').attr('placeholder');
    //-----------------------------------
    if ("ontouchstart" in document.documentElement) {
        $('.form-group').click(function () {
            $(this).addClass('fill');
        });
        $(document).on('touchstart', function (e) {
            var fc = $(".form-group ");
            if (!fc.is(e.target)
                && fc.has(e.target).length === 0) {
                fc.removeClass('fill');
            }
        });
    }
    else {
        $('.form-group').hover(function () {
            $(this).addClass('fill');
            $(this).children('.form-control').focus()
        });
        $(document).mouseout(function (e) {
            var fc = $(".form-group ");
            if (!fc.is(e.target)
                && fc.has(e.target).length === 0) {
                fc.removeClass('fill');
                fc.children('.form-control').blur()
            }
        });
    }

    //-----------------------------------

    function scrollSelect() {
        $(".select2-results__options").niceScroll({
            cursorcolor: "#CBCCD7",
            cursorwidth: "6px",
            cursoropacitymin: 1,
            cursorborder: " 0",
            background: "#ffffff",
            cursorborderradius: " 6px ",
            railpadding: { top: 0, right: 2, left: 0, bottom: 0 },

        });
    }

    $(".dropdown").on("select2:open", function (e) {
        scrollSelect();
    });
    //-----------------------------------
    $(window).scroll(function () {
        if ($(this).scrollTop() > 150) {
            $('header.main').addClass('hidden');
        } else {
            $('header.main').removeClass('hidden');
        }
    });
    //-----------------------------------
    $(".anchorLink").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 1000,
            easing: "swing"
        });
        return false;
    });
    //-----------------------------------
    $(window).scroll(function () {
        var $sections = $('.sideContent h2');
        $sections.each(function (i, el) {
            var top = $(el).offset().top - 1;
            var bottom = top + $(el).height();
            var scroll = $(window).scrollTop();
            var id = $(el).attr('id');
            if (scroll > top && scroll < bottom) {
                $('a.active').removeClass('active');
                $('a[href="#' + id + '"]').addClass('active');
            }
        })
    });
    $(".menuConcept").on("click", "a", function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({ scrollTop: top }, 200);

    });
    //-----------------------------------
    var textAt = $('.article-text .text');
    textAt.outerHeight();
    $.each(textAt, function (i, item) {
        if ($(item).outerHeight() >= 81) {
            $(this).addClass('hidden');
        } else {
            $(this).addClass('show');
        }

    });
    //-----------------------------------
    $('.modal').on('hidden.bs.modal', function (e) {
        $('iframe').attr('src', $('iframe').attr('src'));
    });

    $('#twitter').on('click', function () {
        $('.ya-share2__item_service_twitter').trigger('click');
    });

    $('#fb').on('click', function () {
        $('.ya-share2__item_service_facebook').trigger('click');
    });

    $('#vk').on('click', function () {
        $('.ya-share2__item_service_vkontakte').trigger('click');
    });

    //------------------------------------------

    $('.conceptBox').each(function () {
        if (!$(this).find('.img-wrap img').attr('src')) {
            $(this).addClass('hide-layer');
        }
    });

    //--------------------------------------------

    $('#subscribe-form').on('submit', function () {
        $(this).request('onSubscribe', {
            error: function () {
                let subForm = $('#subscribe-form');
                if (subForm.find('input').val().length > 0) {
                    subForm.find('.form-group').removeClass('error');
                    subForm.find('.form-group').addClass('is-invalid');
                } else {
                    subForm.find('.form-group').removeClass('is-invalid');
                    subForm.find('.form-group').addClass('error');
                }
            },
            success: function () {
                $('.form').hide();
                $('.form-wrap').hide();
                $('.successEl').show();
            }
        });

        return false;
    });

    if (screen.width <= '768') {
        $('.addProduct .flex').addClass('fadeInUp animated');


        $(window).scroll(function () {
            $('.addProduct').removeClass('active')
        })
    }


    //-----------------------------------------------

    $('.closeBtn').on('click', function () {
        $('.tipEl').hide();
    });

    //-------------------------------------------------

    $('#toCollection').on('click', function () {
        window.location.href = '/catalog';
    });

    $(".static table").removeAttr("border width style");

    $('.static table td').each(function () {
        if ($(this).text().length > 0) {
            $(this).parents('table').addClass("table-text");
        }
    });

    $('.static table tr').each(function () {
        let tgImg = $(this).find('td img');
        if (tgImg.length > 0) {
            $(this).parents('table').addClass("table-img");
        }
        if (tgImg.length == 1) {
            $(this).addClass("one-img");
            tgImg.removeAttr('style');
        }
        if (tgImg.length == 2) {
            $(this).addClass("two-img");
            tgImg.removeAttr('style');
        }
        if (tgImg.length == 3) {
            $(this).addClass("three-img");
            tgImg.removeAttr('style');

        }
        if (tgImg.length == 4) {
            $(this).addClass("four-img");
            tgImg.removeAttr('style');
        }
    });

    if ($('a').is('#youtube')) {
        var href = $('#youtube').attr('href');
        $("#youtube").after("" +
            "<div class=\"iframe-wrap\">" +
            "<iframe src=" + href + " frameborder=\"0\" allowfullscreen></iframe>" +
            "</div>" +
            "");
        $('#youtube').remove();
    }
    $('.static a').each(function () {
        $(this).attr("target", "_blank");
        $(this).attr("rel", "nofollow");
    });

    $('.textBlog .text').find('.text').find('img, table, iframe').remove();

    $('.article-text .text').find('img, table, iframe').remove();

    $('#buttonMap').on('click', function () {
        document.location.href = '/buy'
    });

    $('#button').on('click', function () {
        document.location.href = '/catalog'
    });

    fancyBoxImg = $('section.article .static img');
    fancyBoxImg.map(function (index, img) {
        $(img).replaceWith("<a href =" + img.src + " data-fancybox=\"1\"><img src =" + img.src + " /></a>");
    });

    //-----------------------------------
    if ($(window).width() < 768) {
        $('.uspSlider').owlCarousel({
            lazyLoad: true,
            loop: true,
            margin: 24,
            dots: true,
            nav: false,
            items: 1,
            center: true,
            autoWidth: true,
            responsive: {
                0: {
                    margin: 16
                },
                768: {
                    margin: 24
                }
            }
        });
    }
    //-----------------------------------
    $('.articleSlider').owlCarousel({
        lazyLoad: true,
        loop: true,
        autoWidth: true,
        items: 1,
        center: true,
        dots: true,
        nav: true,
        responsive: {
            0: {
                margin: 16
            },
            768: {
                margin: 24
            }
        }
    });
    //-----------------------------------
    $('.commentsSlider').owlCarousel({
        lazyLoad: true,
        loop: true,
        autoWidth: true,
        items: 1,
        center: true,
        dots: true,
        nav: true,
        autoHeight: true,
        responsive: {
            0: {
                margin: 16
            },
            768: {
                margin: 24
            }
        }
    });
});

//-----------------------------------

$('.loader').fadeOut('slow', function () {
});

//-----------------------------------

$('.inSlider').owlCarousel({
    lazyLoad: true,
    loop: false,
    margin: 24,
    dots: false,
    nav: false,
    items: 6,
    autoWidth: true,

});

//-----------------------------------

$("img.lazy").lazyload({
    effect: "fadeIn"
});

$(document).mouseup(function (e) {
    var addProduct = $(".addProduct .flex");
    if (!addProduct.is(e.target)
        && addProduct.has(e.target).length === 0) {
        $(".addProduct").removeClass('active')
    }
});