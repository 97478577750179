module.exports = {
    getPhoneCodes: function () {
        return [
            {
                "name": "Afghanistan",
                "phoneCode": "+93",
                "alpha2code": "AF",
            },
            {
                "name": "Albania",
                "phoneCode": "+355",
                "alpha2code": "AL",
            },
            {
                "name": "Algeria",
                "phoneCode": "+213",
                "alpha2code": "DZ",
            },
            {
                "name": "American Samoa",
                "phoneCode": "+1",
                "alpha2code": "AS",
            },
            {
                "name": "Andorra",
                "phoneCode": "+376",
                "alpha2code": "AD",
            },
            {
                "name": "Angola",
                "phoneCode": "+244",
                "alpha2code": "AO",
            },
            {
                "name": "Anguilla",
                "phoneCode": "+1",
                "alpha2code": "AI",
            },
            {
                "name": "Antigua and Barbuda",
                "phoneCode": "+1",
                "alpha2code": "AG",
            },
            {
                "name": "Argentina",
                "phoneCode": "+54",
                "alpha2code": "AR",
            },
            {
                "name": "Armenia",
                "phoneCode": "+374",
                "alpha2code": "AM",
            },
            {
                "name": "Aruba",
                "phoneCode": "+297",
                "alpha2code": "AW",
            },
            {
                "name": "Australia",
                "phoneCode": "+61",
                "alpha2code": "AU",
            },
            {
                "name": "Austria",
                "phoneCode": "+43",
                "alpha2code": "AT",
            },
            {
                "name": "Azerbaijan",
                "phoneCode": "+994",
                "alpha2code": "AZ",
            },
            {
                "name": "Bahrain",
                "phoneCode": "+973",
                "alpha2code": "BH",
            },
            {
                "name": "Bangladesh",
                "phoneCode": "+880",
                "alpha2code": "BD",
            },
            {
                "name": "Barbados",
                "phoneCode": "+1",
                "alpha2code": "BB",
            },
            {
                "name": "Belarus",
                "phoneCode": "+375",
                "alpha2code": "BY",
            },
            {
                "name": "Belgium",
                "phoneCode": "+32",
                "alpha2code": "BE",
            },
            {
                "name": "Belize",
                "phoneCode": "+501",
                "alpha2code": "BZ",
            },
            {
                "name": "Benin",
                "phoneCode": "+229",
                "alpha2code": "BJ",
            },
            {
                "name": "Bermuda",
                "phoneCode": "+1",
                "alpha2code": "BM",
            },
            {
                "name": "Bhutan",
                "phoneCode": "+975",
                "alpha2code": "BT",
            },
            {
                "name": "Bolivia",
                "phoneCode": "+591",
                "alpha2code": "BO",
            },
            {
                "name": "Bonaire, Sint Eustatius and Saba",
                "phoneCode": "+599",
                "alpha2code": "BQ",
            },
            {
                "name": "Bosnia and Herzegovina",
                "phoneCode": "+387",
                "alpha2code": "BA",
            },
            {
                "name": "Botswana",
                "phoneCode": "+267",
                "alpha2code": "BW",
            },
            {
                "name": "Brazil",
                "phoneCode": "+55",
                "alpha2code": "BR",
            },
            {
                "name": "British Indian Ocean Territory",
                "phoneCode": "+246",
                "alpha2code": "IO",
            },
            {
                "name": "British Virgin Islands",
                "phoneCode": "+1",
                "alpha2code": "VG",
            },
            {
                "name": "Brunei Darussalam",
                "phoneCode": "+673",
                "alpha2code": "BN",
            },
            {
                "name": "Bulgaria",
                "phoneCode": "+359",
                "alpha2code": "BG",
            },
            {
                "name": "Burkina Faso",
                "phoneCode": "+226",
                "alpha2code": "BF",
            },
            {
                "name": "Myanmar",
                "phoneCode": "+95",
                "alpha2code": "MM",
            },
            {
                "name": "Burundi",
                "phoneCode": "+257",
                "alpha2code": "BI",
            },
            {
                "name": "Cambodia",
                "phoneCode": "+855",
                "alpha2code": "KH",
            },
            {
                "name": "Cameroon",
                "phoneCode": "+237",
                "alpha2code": "CM",
            },
            {
                "name": "Canada",
                "phoneCode": "+1",
                "alpha2code": "CA",
            },
            {
                "name": "Cabo Verde",
                "phoneCode": "+238",
                "alpha2code": "CV",
            },
            {
                "name": "Cayman Islands",
                "phoneCode": "+1",
                "alpha2code": "KY",
            },
            {
                "name": "Central African Republic",
                "phoneCode": "+236",
                "alpha2code": "CF",
            },
            {
                "name": "Chad",
                "phoneCode": "+235",
                "alpha2code": "ID",
            },
            {
                "name": "Chile",
                "phoneCode": "+56",
                "alpha2code": "CL",
            },
            {
                "name": "China",
                "phoneCode": "+86",
                "alpha2code": "CN",
            },
            {
                "name": "Colombia",
                "phoneCode": "+57",
                "alpha2code": "CO",
            },
            {
                "name": "Comoros",
                "phoneCode": "+269",
                "alpha2code": "KM",
            },
            {
                "name": "Cook Islands",
                "phoneCode": "+682",
                "alpha2code": "CK",
            },
            {
                "name": "Costa Rica",
                "phoneCode": "+506",
                "alpha2code": "CR",
            },
            {
                "name": "Côte dIvoire",
                "phoneCode": "+225",
                "alpha2code": "CI",
            },
            {
                "name": "Croatia",
                "phoneCode": "+385",
                "alpha2code": "HR",
            },
            {
                "name": "Cuba",
                "phoneCode": "+53",
                "alpha2code": "CU",
            },
            {
                "name": "Curaçao",
                "phoneCode": "+599",
                "alpha2code": "CW",
            },
            {
                "name": "Cyprus",
                "phoneCode": "+357",
                "alpha2code": "CY",
            },
            {
                "name": "Czechia",
                "phoneCode": "+420",
                "alpha2code": "CZ",
            },
            {
                "name": "Denmark",
                "phoneCode": "+45",
                "alpha2code": "DK",
            },
            {
                "name": "Djibouti",
                "phoneCode": "+253",
                "alpha2code": "DJ",
            },
            {
                "name": "Dominica",
                "phoneCode": "+1",
                "alpha2code": "DM",
            },
            {
                "name": "Dominican Republic",
                "phoneCode": "+1",
                "alpha2code": "DO",
            },
            {
                "name": "Ecuador",
                "phoneCode": "+593",
                "alpha2code": "EC",
            },
            {
                "name": "Egypt",
                "phoneCode": "+20",
                "alpha2code": "EG",
            },
            {
                "name": "El Salvador",
                "phoneCode": "+503",
                "alpha2code": "SV",
            },
            {
                "name": "Equatorial Guinea",
                "phoneCode": "+240",
                "alpha2code": "GQ",
            },
            {
                "name": "Eritrea",
                "phoneCode": "+291",
                "alpha2code": "ER",
            },
            {
                "name": "Estonia",
                "phoneCode": "+372",
                "alpha2code": "EE",
            },
            {
                "name": "Ethiopia",
                "phoneCode": "+251",
                "alpha2code": "ET",
            },
            {
                "name": "Falkland Islands",
                "phoneCode": "+500",
                "alpha2code": "FK",
            },
            {
                "name": "Faroe Islands",
                "phoneCode": "+298",
                "alpha2code": "FO",
            },
            {
                "name": "Federated States of Micronesia",
                "phoneCode": "+691",
                "alpha2code": "FM",
            },
            {
                "name": "Fiji",
                "phoneCode": "+679",
                "alpha2code": "FJ",
            },
            {
                "name": "Finland",
                "phoneCode": "+358",
                "alpha2code": "FI",
            },
            {
                "name": "France",
                "phoneCode": "+33",
                "alpha2code": "FR",
            },
            {
                "name": "French Guiana",
                "phoneCode": "+594",
                "alpha2code": "GF",
            },
            {
                "name": "French Polynesia",
                "phoneCode": "+689",
                "alpha2code": "PF",
            },
            {
                "name": "Gabon",
                "phoneCode": "+241",
                "alpha2code": "GA",
            },
            {
                "name": "Georgia",
                "phoneCode": "+995",
                "alpha2code": "GE",
            },
            {
                "name": "Germany",
                "phoneCode": "+49",
                "alpha2code": "DE",
            },
            {
                "name": "Ghana",
                "phoneCode": "+233",
                "alpha2code": "GH",
            },
            {
                "name": "Gibraltar",
                "phoneCode": "+350",
                "alpha2code": "GI",
            },
            {
                "name": "Greece",
                "phoneCode": "+30",
                "alpha2code": "GR",
            },
            {
                "name": "Greenland",
                "phoneCode": "+299",
                "alpha2code": "GL",
            },
            {
                "name": "Grenada",
                "phoneCode": "+1",
                "alpha2code": "GD",
            },
            {
                "name": "Guadeloupe",
                "phoneCode": "+590",
                "alpha2code": "GP",
            },
            {
                "name": "Guam",
                "phoneCode": "+1",
                "alpha2code": "GU",
            },
            {
                "name": "Guatemala",
                "phoneCode": "+502",
                "alpha2code": "GT",
            },
            {
                "name": "Guinea",
                "phoneCode": "+224",
                "alpha2code": "GN",
            },
            {
                "name": "Guinea-Bissau",
                "phoneCode": "+245",
                "alpha2code": "GW",
            },
            {
                "name": "Guyana",
                "phoneCode": "+592",
                "alpha2code": "GY",
            },
            {
                "name": "Haiti",
                "phoneCode": "+509",
                "alpha2code": "HT",
            },
            {
                "name": "Honduras",
                "phoneCode": "+504",
                "alpha2code": "HN",
            },
            {
                "name": "Hong Kong",
                "phoneCode": "+852",
                "alpha2code": "HK",
            },
            {
                "name": "Hungary",
                "phoneCode": "+36",
                "alpha2code": "HU",
            },
            {
                "name": "Iceland",
                "phoneCode": "+354",
                "alpha2code": "IS",
            },
            {
                "name": "India",
                "phoneCode": "+91",
                "alpha2code": "IN",
            },
            {
                "name": "Indonesia",
                "phoneCode": "+62",
                "alpha2code": "ID",
            },
            {
                "name": "Iran",
                "phoneCode": "+98",
                "alpha2code": "IR",
            },
            {
                "name": "Iraq",
                "phoneCode": "+964",
                "alpha2code": "IQ",
            },
            {
                "name": "Ireland",
                "phoneCode": "+353",
                "alpha2code": "IE",
            },
            {
                "name": "Israel",
                "phoneCode": "+972",
                "alpha2code": "IL",
            },
            {
                "name": "Italy",
                "phoneCode": "+39",
                "alpha2code": "IT",
            },
            {
                "name": "Jamaica",
                "phoneCode": "+1",
                "alpha2code": "JM",
            },
            {
                "name": "Japan",
                "phoneCode": "+81",
                "alpha2code": "JP",
            },
            {
                "name": "Jordan",
                "phoneCode": "+962",
                "alpha2code": "JO",
            },
            {
                "name": "Kazakhstan",
                "phoneCode": "+7",
                "alpha2code": "KZ",
            },
            {
                "name": "Kenya",
                "phoneCode": "+254",
                "alpha2code": "KE",
            },
            {
                "name": "Kiribati",
                "phoneCode": "+686",
                "alpha2code": "KI",
            },
            {
                "name": "Kuwait",
                "phoneCode": "+965",
                "alpha2code": "KW",
            },
            {
                "name": "Kyrgyzstan",
                "phoneCode": "+996",
                "alpha2code": "KG",
            },
            {
                "name": "Laos",
                "phoneCode": "+856",
                "alpha2code": "LA",
            },
            {
                "name": "Latvia",
                "phoneCode": "+371",
                "alpha2code": "LV",
            },
            {
                "name": "Lebanon",
                "phoneCode": "+961",
                "alpha2code": "LB",
            },
            {
                "name": "Lesotho",
                "phoneCode": "+266",
                "alpha2code": "LS",
            },
            {
                "name": "Liberia",
                "phoneCode": "+231",
                "alpha2code": "LR",
            },
            {
                "name": "Libya",
                "phoneCode": "+218",
                "alpha2code": "LY",
            },
            {
                "name": "Liechtenstein",
                "phoneCode": "+423",
                "alpha2code": "LI",
            },
            {
                "name": "Lithuania",
                "phoneCode": "+370",
                "alpha2code": "LT",
            },
            {
                "name": "Luxembourg",
                "phoneCode": "+352",
                "alpha2code": "LU",
            },
            {
                "name": "Macao",
                "phoneCode": "+853",
                "alpha2code": "MO",
            },
            {
                "name": "Macedonia",
                "phoneCode": "+389",
                "alpha2code": "MK",
            },
            {
                "name": "Madagascar",
                "phoneCode": "+261",
                "alpha2code": "MG",
            },
            {
                "name": "Malawi",
                "phoneCode": "+265",
                "alpha2code": "MW",
            },
            {
                "name": "Malaysia",
                "phoneCode": "+60",
                "alpha2code": "MY",
            },
            {
                "name": "Maldives",
                "phoneCode": "+960",
                "alpha2code": "MV",
            },
            {
                "name": "Mali",
                "phoneCode": "+223",
                "alpha2code": "ML",
            },
            {
                "name": "Malta",
                "phoneCode": "+356",
                "alpha2code": "MT",
            },
            {
                "name": "Marshall Islands",
                "phoneCode": "+692",
                "alpha2code": "MH",
            },
            {
                "name": "Martinique",
                "phoneCode": "+596",
                "alpha2code": "MQ",
            },
            {
                "name": "Mauritania",
                "phoneCode": "+222",
                "alpha2code": "MR",
            },
            {
                "name": "Mauritius",
                "phoneCode": "+230",
                "alpha2code": "MU",
            },
            {
                "name": "Mayotte",
                "phoneCode": "+262",
                "alpha2code": "YT",
            },
            {
                "name": "Mexico",
                "phoneCode": "+52",
                "alpha2code": "MX",
            },
            {
                "name": "Moldova",
                "phoneCode": "+373",
                "alpha2code": "MD",
            },
            {
                "name": "Monaco",
                "phoneCode": "+377",
                "alpha2code": "MC",
            },
            {
                "name": "Mongolia",
                "phoneCode": "+976",
                "alpha2code": "MN",
            },
            {
                "name": "Montenegro",
                "phoneCode": "+382",
                "alpha2code": "ME",
            },
            {
                "name": "Montserrat",
                "phoneCode": "+1",
                "alpha2code": "MS",
            },
            {
                "name": "Morocco",
                "phoneCode": "+212",
                "alpha2code": "MA",
            },
            {
                "name": "Mozambique",
                "phoneCode": "+258",
                "alpha2code": "MZ",
            },
            {
                "name": "Namibia",
                "phoneCode": "+264",
                "alpha2code": "NA",
            },
            {
                "name": "Nauru",
                "phoneCode": "+674",
                "alpha2code": "NR",
            },
            {
                "name": "Nepal",
                "phoneCode": "+977",
                "alpha2code": "NP",
            },
            {
                "name": "Netherlands",
                "phoneCode": "+31",
                "alpha2code": "NL",
            },
            {
                "name": "Netherlands Antilles",
                "phoneCode": "+599",
                "alpha2code": "AN",
            },
            {
                "name": "New Caledonia",
                "phoneCode": "+687",
                "alpha2code": "NC",
            },
            {
                "name": "New Zealand",
                "phoneCode": "+64",
                "alpha2code": "NZ",
            },
            {
                "name": "Nicaragua",
                "phoneCode": "+505",
                "alpha2code": "NI",
            },
            {
                "name": "Niger",
                "phoneCode": "+227",
                "alpha2code": "NE",
            },
            {
                "name": "Nigeria",
                "phoneCode": "+234",
                "alpha2code": "NG",
            },
            {
                "name": "Niue",
                "phoneCode": "+683",
                "alpha2code": "NU",
            },
            {
                "name": "Norfolk Island",
                "phoneCode": "+672",
                "alpha2code": "NF",
            },
            {
                "name": "North Korea",
                "phoneCode": "+850",
                "alpha2code": "KP",
            },
            {
                "name": "Northern Mariana Islands",
                "phoneCode": "+1",
                "alpha2code": "MP",
            },
            {
                "name": "Norway",
                "phoneCode": "+47",
                "alpha2code": "NO",
            },
            {
                "name": "Oman",
                "phoneCode": "+968",
                "alpha2code": "OM",
            },
            {
                "name": "Pakistan",
                "phoneCode": "+92",
                "alpha2code": "PK",
            },
            {
                "name": "Palau",
                "phoneCode": "+680",
                "alpha2code": "PW",
            },
            {
                "name": "Palestine",
                "phoneCode": "+970",
                "alpha2code": "PS",
            },
            {
                "name": "Panama",
                "phoneCode": "+507",
                "alpha2code": "PA",
            },
            {
                "name": "Papua New Guinea",
                "phoneCode": "+675",
                "alpha2code": "PG",
            },
            {
                "name": "Paraguay",
                "phoneCode": "+595",
                "alpha2code": "PY",
            },
            {
                "name": "Peru",
                "phoneCode": "+51",
                "alpha2code": "PE",
            },
            {
                "name": "Philippines",
                "phoneCode": "+63",
                "alpha2code": "PH",
            },
            {
                "name": "Poland",
                "phoneCode": "+48",
                "alpha2code": "PL",
            },
            {
                "name": "Portugal",
                "phoneCode": "+351",
                "alpha2code": "PT",
            },
            {
                "name": "Puerto Rico",
                "phoneCode": "+1",
                "alpha2code": "PR",
            },
            {
                "name": "Qatar",
                "phoneCode": "+974",
                "alpha2code": "QA",
            },
            {
                "name": "Congo",
                "phoneCode": "+242",
                "alpha2code": "CG",
            },
            {
                "name": "Réunion",
                "phoneCode": "+262",
                "alpha2code": "RE",
            },
            {
                "name": "Romania",
                "phoneCode": "+40",
                "alpha2code": "RO",
            },
            {
                "name": "Russia",
                "phoneCode": "+7",
                "alpha2code": "RU",
            },
            {
                "name": "Rwanda",
                "phoneCode": "+250",
                "alpha2code": "RW",
            },
            {
                "name": "Saint Barthélemy",
                "phoneCode": "+590",
                "alpha2code": "BL",
            },
            {
                "name": "Saint Helena",
                "phoneCode": "+290",
                "alpha2code": "SH",
            },
            {
                "name": "Saint Kitts and Nevis",
                "phoneCode": "+1",
                "alpha2code": "KN",
            },
            {
                "name": "Saint Martin",
                "phoneCode": "+590",
                "alpha2code": "MF",
            },
            {
                "name": "Saint Pierre and Miquelon",
                "phoneCode": "+508",
                "alpha2code": "PM",
            },
            {
                "name": "Saint Vincent and the Grenadines",
                "phoneCode": "+1",
                "alpha2code": "VC",
            },
            {
                "name": "Samoa",
                "phoneCode": "+685",
                "alpha2code": "WS",
            },
            {
                "name": "San Marino",
                "phoneCode": "+378",
                "alpha2code": "SM",
            },
            {
                "name": "São Tomé and Príncipe",
                "phoneCode": "+239",
                "alpha2code": "ST",
            },
            {
                "name": "Saudi Arabia",
                "phoneCode": "+966",
                "alpha2code": "SA",
            },
            {
                "name": "Senegal",
                "phoneCode": "+221",
                "alpha2code": "SN",
            },
            {
                "name": "Serbia",
                "phoneCode": "+381",
                "alpha2code": "RS",
            },
            {
                "name": "Seychelles",
                "phoneCode": "+248",
                "alpha2code": "SC",
            },
            {
                "name": "Sierra Leone",
                "phoneCode": "+232",
                "alpha2code": "SL",
            },
            {
                "name": "Singapore",
                "phoneCode": "+65",
                "alpha2code": "SG",
            },
            {
                "name": "Sint Maarten (Dutch part)",
                "phoneCode": "+1",
                "alpha2code": "SX",
            },
            {
                "name": "Slovakia",
                "phoneCode": "+421",
                "alpha2code": "SK",
            },
            {
                "name": "Slovenia",
                "phoneCode": "+386",
                "alpha2code": "SI",
            },
            {
                "name": "Solomon Islands",
                "phoneCode": "+677",
                "alpha2code": "SB",
            },
            {
                "name": "Somalia",
                "phoneCode": "+252",
                "alpha2code": "SO",
            },
            {
                "name": "South Africa",
                "phoneCode": "+27",
                "alpha2code": "ZA",
            },
            {
                "name": "South Korea",
                "phoneCode": "+82",
                "alpha2code": "KR",
            },
            {
                "name": "South Sudan",
                "phoneCode": "+211",
                "alpha2code": "SS",
            },
            {
                "name": "Spain",
                "phoneCode": "+34",
                "alpha2code": "ES",
            },
            {
                "name": "Sri Lanka",
                "phoneCode": "+94",
                "alpha2code": "LK",
            },
            {
                "name": "Saint Lucia",
                "phoneCode": "+1",
                "alpha2code": "LC",
            },
            {
                "name": "Sudan",
                "phoneCode": "+249",
                "alpha2code": "SD",
            },
            {
                "name": "Suriname",
                "phoneCode": "+597",
                "alpha2code": "SR",
            },
            {
                "name": "Swaziland",
                "phoneCode": "+268",
                "alpha2code": "SZ",
            },
            {
                "name": "Sweden",
                "phoneCode": "+46",
                "alpha2code": "SE",
            },
            {
                "name": "Switzerland",
                "phoneCode": "+41",
                "alpha2code": "CH",
            },
            {
                "name": "Syrian Arab Republic",
                "phoneCode": "+963",
                "alpha2code": "SY",
            },
            {
                "name": "Taiwan",
                "phoneCode": "+886",
                "alpha2code": "TW",
            },
            {
                "name": "Tajikistan",
                "phoneCode": "+992",
                "alpha2code": "TJ",
            },
            {
                "name": "Tanzania",
                "phoneCode": "+255",
                "alpha2code": "TZ",
            },
            {
                "name": "Thailand",
                "phoneCode": "+66",
                "alpha2code": "TH",
            },
            {
                "name": "Bahamas",
                "phoneCode": "+1",
                "alpha2code": "BS",
            },
            {
                "name": "Gambia",
                "phoneCode": "+220",
                "alpha2code": "GM",
            },
            {
                "name": "Timor-Leste",
                "phoneCode": "+670",
                "alpha2code": "TL",
            },
            {
                "name": "Togo",
                "phoneCode": "+228",
                "alpha2code": "TG",
            },
            {
                "name": "Tokelau",
                "phoneCode": "+690",
                "alpha2code": "TK",
            },
            {
                "name": "Tonga",
                "phoneCode": "+676",
                "alpha2code": "TO",
            },
            {
                "name": "Trinidad and Tobago",
                "phoneCode": "+1",
                "alpha2code": "TT",
            },
            {
                "name": "Tunisia",
                "phoneCode": "+216",
                "alpha2code": "TN",
            },
            {
                "name": "Turkey",
                "phoneCode": "+90",
                "alpha2code": "TR",
            },
            {
                "name": "Turkmenistan",
                "phoneCode": "+993",
                "alpha2code": "TM",
            },
            {
                "name": "Turks and Caicos Islands",
                "phoneCode": "+1",
                "alpha2code": "TC",
            },
            {
                "name": "Tuvalu",
                "phoneCode": "+688",
                "alpha2code": "TV",
            },
            {
                "name": "Uganda",
                "phoneCode": "+256",
                "alpha2code": "UG",
            },
            {
                "name": "Ukraine",
                "phoneCode": "+380",
                "alpha2code": "UA",
            },
            {
                "name": "United Arab Emirates",
                "phoneCode": "+971",
                "alpha2code": "AE",
            },
            {
                "name": "United Kingdom",
                "phoneCode": "+44",
                "alpha2code": "GB",
            },
            {
                "name": "United States",
                "phoneCode": "+1",
                "alpha2code": "US",
            },
            {
                "name": "Uruguay",
                "phoneCode": "+598",
                "alpha2code": "UY",
            },
            {
                "name": "U.S. Virgin Islands",
                "phoneCode": "+1",
                "alpha2code": "VI",
            },
            {
                "name": "Uzbekistan",
                "phoneCode": "+998",
                "alpha2code": "UZ",
            },
            {
                "name": "Vanuatu",
                "phoneCode": "+678",
                "alpha2code": "VU",
            },
            {
                "name": "Vatican City",
                "phoneCode": "+39",
                "alpha2code": "VA",
            },
            {
                "name": "Venezuela",
                "phoneCode": "+58",
                "alpha2code": "VE",
            },
            {
                "name": "Vietnam",
                "phoneCode": "+84",
                "alpha2code": "VN",
            },
            {
                "name": "Wallis and Futuna",
                "phoneCode": "+681",
                "alpha2code": "WF",
            },
            {
                "name": "Yemen",
                "phoneCode": "+967",
                "alpha2code": "YE",
            },
            {
                "name": "Zambia",
                "phoneCode": "+260",
                "alpha2code": "ZM",
            },
            {
                "name": "Zimbabwe",
                "phoneCode": "+263",
                "alpha2code": "ZW",
            }
        ]
    }

}