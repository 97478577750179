try {
    require('popper.js');
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {
}

require('@fancyapps/fancybox');
require('select2');
require('owl.carousel');
require('jquery.nicescroll');
require('bootstrap-input-spinner');
require('air-datepicker');
require('jquery.maskedinput/src/jquery.maskedinput');

window.objectFitImages = require('object-fit-images');
const WOW = require('wowjs');

window.wow = new WOW.WOW({
    live: false
});